<template>
    <!-- compose email -->
    <vs-popup :title="title" :active.sync="showModal">
        <div id="div-with-loading" class="vs-con-loading__container">
            <div v-if="couponData" class="vx-row mb-2">
                <div v-if="!coupon" class="vx-col w-full mb-6">
                    <vs-input
                        v-model="couponData.code"
                        type="text"
                        label="Code"
                        label-placeholer="Code"
                        :danger="errors.code !== null"
                        :danger-text="errors.code"
                        class="w-full"
                    />
                </div>

                <div class="vx-col w-full">
                    <BrokerSelect
                        @selectedBroker="setBroker"
                        :initialBroker="initialBroker"
                    />
                </div>
                <div class="vx-col w-full mb-6">
                    <UserSelect
                        @selectedUser="setUser"
                        :initialUser="initialUser"
                    />
                </div>
            </div>

            <div class="vx-row">
                <div class="vx-col w-1/2">
                    <vs-checkbox v-model="couponData.one_time_use" class="mb-2"
                        >One Time Use
                    </vs-checkbox>
                    <vs-checkbox v-model="couponData.partner_code" class="mb-2"
                        >Partner Code</vs-checkbox
                    >
                    <vs-checkbox v-model="couponData.used" class="mb-2"
                        >Used</vs-checkbox
                    >
                </div>
                <div class="vx-col w-1/2 mb-6">
                    <vs-input
                        type="date"
                        label="Expiry Date"
                        v-model="couponData.expiry_date"
                        class="mb-2"
                        >Partner Code
                    </vs-input>
                </div>
            </div>
            <div class="vx-row">
                <div class="vx-col w-full">
                    <vs-input
                        v-model="couponData.price_discount_percentage"
                        type="number"
                        label="Price Discount Percentage"
                        class="w-full"
                        :danger="errors.price_discount_percentage"
                        :danger-text="errors.price_discount_percentage"
                    />
                </div>

                <div class="vx-col w-full">
                    <div class="vx-row">
                        <div class="vx-col w-5/6">
                            <vs-input
                                v-model="couponData.price_fixed_single"
                                type="number"
                                label="Price Fixed Single"
                                class="w-full"
                                :danger="errors.price_fixed_single !== null"
                                :danger-text="errors.price_fixed_single"
                            />
                        </div>
                        <div class="vx-col w-1/6 flex content-end">
                            <span
                                class="mt-6"
                                v-if="couponData.price_fixed_single"
                                >£{{
                                    couponData.price_fixed_single / 100
                                }}</span
                            >
                        </div>
                    </div>
                </div>
                <div class="vx-col w-full">
                    <div class="vx-row">
                        <div class="vx-col w-5/6">
                            <vs-input
                                v-model="couponData.price_fixed_joint"
                                type="number"
                                label="Price Fixed Single"
                                class="w-full"
                                :danger="errors.price_fixed_joint !== null"
                                :danger-text="errors.price_fixed_joint"
                            />
                        </div>
                        <div class="vx-col w-1/6 flex content-end">
                            <span
                                class="mt-6"
                                v-if="couponData.price_fixed_joint"
                                >£{{ couponData.price_fixed_joint / 100 }}</span
                            >
                        </div>
                    </div>
                </div>
            </div>
            <vs-divider />
            <div class="vs-row">
                <div class="flex">
                    <vs-button
                        v-on:click="couponSave"
                        color="success"
                        class="mr-3"
                    >
                        {{ buttonSaveText }}
                    </vs-button>
                    <vs-button v-on:click="close" type="border" class="mr-3"
                        >Cancel
                    </vs-button>
                    <vs-button
                        v-on:click="couponDeleteConfirm"
                        type="border"
                        color="secondary"
                        icon-pack="feather"
                        icon="icon-trash"
                    ></vs-button>
                </div>
            </div>
        </div>
    </vs-popup>
</template>

<script>
import { http } from '@/services'
import { functions } from '@/mixins/functions'
import BrokerSelect from '@/components/common/BrokerSelect'
import UserSelect from '../../../../../components/common/UserSelect'

export default {
    name: 'CouponModal',
    props: ['show', 'coupon'],
    mixins: [functions],
    components: {
        BrokerSelect,
        UserSelect,
    },
    watch: {
        coupon: {
            deep: true,
            immediate: true,
            handler(value) {
                console.log('get coupon : ', value)
                if (value) this.getCoupon(value)
                else this.initialiseCouponData()
            },
        },
    },
    computed: {
        title() {
            if (this.coupon) return `Coupon - ${this.coupon}`
            return 'Coupon'
        },
        showModal: {
            get() {
                return this.show
            },
            set(value) {
                if (value === false) this.close()
            },
        },
        buttonSaveText() {
            if (this.coupon) return 'Save'
            return 'Create'
        },
        validatedForm() {
            if (
                this.couponData &&
                this.couponData.code &&
                (this.couponData.price_discount_percentage ||
                    (this.couponData.price_fixed_joint &&
                        this.couponData.price_fixed_single))
            ) {
                return true
            }
            return false
        },
    },
    data() {
        return {
            couponData: null,
            initialBroker: null,
            initialUser: null,
            errors: {
                code: null,
                price_discount_percentage: null,
                price_fixed_single: null,
                price_fixed_joint: null,
                partner_code: null,
            },
        }
    },
    methods: {
        initialiseCouponData() {
            this.couponData = {
                broker: null,
                user: null,
                code: null,
                one_time_use: true,
                used: false,
                price_discount_percentage: null,
                price_fixed_single: null,
                price_fixed_joint: null,
                partner_code: false,
            }
            this.initialBroker = null
            this.initialUser = null
        },
        close() {
            this.$emit('close')
            if (this.coupon) {
                this.couponData = null
                this.initialBroker = null
                this.initialUser = null
            }
        },
        setBroker(value) {
            if (value) {
                this.couponData.broker = value.value
            } else this.couponData.broker = null
        },
        setUser(value) {
            if (value) {
                this.couponData.user = value.value
            } else this.couponData.user = null
        },
        getCoupon(code) {
            http.get('coupon_detail', { params: { code: code } })
                .then((response) => {
                    this.couponData = response.data
                    if (this.couponData && this.couponData.broker) {
                        this.initialBroker = {
                            value: this.couponData.broker.email,
                            label: this.couponData.broker.company_name,
                        }
                        this.couponData.broker = this.couponData.broker.email
                    }
                    if (this.couponData && this.couponData.user) {
                        this.initialUser = {
                            value: this.couponData.user.details.user_id,
                            label:
                                this.couponData.user.details.full_name +
                                ' - ' +
                                this.couponData.user.email,
                        }
                        this.couponData.user = this.couponData.user.details.user_id
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        },
        couponSave() {
            this.clearErrors()
            var dataCopy = Object.assign({}, this.couponData)

            if (this.couponData.broker && this.couponData.broker.email)
                dataCopy.broker = this.couponData.broker.email
            if (this.couponData.user && this.couponData.user.details)
                dataCopy.user = this.couponData.user.details.user_id
            if (!this.couponData.price_discount_percentage)
                dataCopy.price_discount_percentage = null
            if (
                this.couponData.price_fixed_single === null ||
                !this.couponData.price_fixed_joint
            )
                dataCopy.price_fixed_single = null
            if (!this.couponData.price_fixed_joint)
                dataCopy.price_fixed_joint = null

            if (this.coupon && this.couponData && this.couponData.code) {
                http.patch('coupon_detail', dataCopy)
                    .then((response) => {
                        this.$emit('refresh')
                        this.$emit('close')
                        this.$vs.notify({
                            text: 'Saved',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        console.log(error)
                        this.errorHandler(error.response.data)
                    })
            } else if (this.couponData && this.couponData.code) {
                http.post('coupon_detail', dataCopy)
                    .then((response) => {
                        this.$emit('refresh')
                        this.$emit('close')
                        this.$vs.notify({
                            text: 'Created',
                            color: 'success',
                        })
                    })
                    .catch((error) => {
                        this.errorHandler(error.response.data)
                    })
            }
        },
        couponDeleteConfirm() {
            this.$vs.dialog({
                type: 'confirm',
                color: 'secondary',
                title: 'Delete',
                acceptText: 'Delete',
                text: `Delete Coupon ${this.couponData.code}`,
                accept: this.couponDelete,
            })
            this.$emit('closeModal')
        },
        couponDelete() {
            http.delete('coupon_detail', {
                params: { code: this.couponData.code },
            }).then((response) => {
                this.$vs.notify({
                    text: 'Coupon Deleted',
                    color: 'success',
                })
                this.$emit('refresh')
                this.$emit('close')
            })
        },
        clearErrors() {
            this.errors.code = null
            this.errors.price_discount_percentage = null
            this.errors.price_fixed_joint = null
            this.errors.price_fixed_single = null
            this.errors.partner_code = null
        },
        errorHandler(error) {
            if (error.code) {
                this.errors.code = error.code[0]
            }
            if (error.broker) {
                this.errors.broker = error.broker[0]
            }
            if (error.user) {
                this.errors.user = error.user[0]
            }
            if (error.price_discount_percentage) {
                this.errors.price_discount_percentage =
                    error.price_discount_percentage[0]
            }
            if (error.partner_code) {
                this.errors.partner_code = error.partner_code[0]
            }
            if (error.price_fixed_joint) {
                this.errors.price_fixed_joint = error.price_fixed_joint[0]
            }
            if (error.price_fixed_single) {
                this.errors.price_fixed_single = error.price_fixed_single[0]
            }
            if (error.non_field_errors) {
                this.$vs.notify({
                    text: error.non_field_errors[0],
                    color: 'secondary',
                })
            }
        },
    },
}
</script>
