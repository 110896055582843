<template>
    <vs-table
        ref="table"
        v-model="selected"
        search
        :sst="true"
        :data="coupons"
        @sort="handleSort"
        @search="search"
        @selected="selectedCoupon"
    >
        <div
            slot="header"
            class="flex flex-wrap-reverse items-center flex-grow justify-end"
        >
            <p v-show="searchQuery" class="mr-4 mb-4">
                Search results for '{{ searchQuery }}'
            </p>
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> Action </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>

                <vs-dropdown-menu
                    class="w-full sm:w-full md:w-1/3 lg:w-1/3 xl:w-1/6"
                >
                    <vs-dropdown-item v-on:click="$emit('createNewCoupon')">
                        Create New
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>

            <!-- ITEMS PER PAGE -->
            <vs-dropdown
                vs-trigger-click
                class="cursor-pointer mb-4 mr-4 items-per-page-handler"
            >
                <div
                    class="p-4 border border-solid d-theme-border-grey-light rounded-full d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                >
                    <span class="mr-2"> {{ itemsPerPage }} </span>
                    <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                </div>
                <vs-dropdown-menu>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 5)">
                        <span>5</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 10)">
                        <span>10</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 20)">
                        <span>20</span>
                    </vs-dropdown-item>
                    <vs-dropdown-item @click="$emit('itemsPerPage', 50)">
                        <span>50</span>
                    </vs-dropdown-item>
                </vs-dropdown-menu>
            </vs-dropdown>
        </div>

        <template slot="thead">
            <vs-th sort-key="user__personaldetails__full_name">Name</vs-th>
            <vs-th>User</vs-th>
            <vs-th>Classification</vs-th>
            <vs-th sort-key="uploaded_at">Broker</vs-th>
            <vs-th sort-key="user__email">Used</vs-th>
            <vs-th sort-key="coupon_used__broker">Price</vs-th>
        </template>

        <template slot-scope="{ data }">
            <tbody>
                <vs-tr
                    :data="coupon"
                    :key="index"
                    v-for="(coupon, index) in data"
                >
                    <vs-td>
                        {{ coupon.code }}
                    </vs-td>

                    <vs-td>
                        <a
                            v-if="coupon.user && coupon.user.details"
                            class="link"
                            @click="
                                $router.push({
                                    name: 'user',
                                    params: { id: coupon.user.details.user_id },
                                })
                            "
                        >
                            {{ coupon.user.details.full_name }}</a
                        >
                    </vs-td>
                    <vs-td>
                        <vs-chip v-if="coupon.partner_code"
                            >Partner Code</vs-chip
                        >
                        <vs-chip v-if="coupon.site_code">Site Code</vs-chip>
                        <vs-chip v-if="brokerAccount(coupon)"
                            >Broker Created Account</vs-chip
                        >
                    </vs-td>

                    <vs-td>
                        <a
                            v-if="coupon.broker"
                            class="link"
                            @click="
                                $router.push({
                                    name: 'broker',
                                    params: { pk: coupon.broker.email },
                                })
                            "
                        >
                            {{ coupon.broker.company_name }}</a
                        >
                    </vs-td>

                    <vs-td>
                        <vs-chip v-if="coupon.used" color="secondary"
                            >Used</vs-chip
                        >
                        <vs-chip v-else color="success">Active</vs-chip>
                    </vs-td>

                    <vs-td>
                        <vs-chip v-if="coupon.price_discount_percentage"
                            >{{ coupon.price_discount_percentage }}%
                            off</vs-chip
                        >
                        <vs-chip
                            v-if="
                                coupon.price_fixed_single !== null &&
                                coupon.price_fixed_joint !== null
                            "
                        >
                            £{{ coupon.price_fixed_single / 100 }} or £{{
                                coupon.price_fixed_joint / 100
                            }}
                        </vs-chip>
                    </vs-td>
                </vs-tr>
            </tbody>
        </template>
    </vs-table>
</template>

<script>
import { textFormat } from '@/mixins/TextFormat'
import { tableHelpers } from '@/mixins/tableHelpers'

export default {
    name: 'CouponsAllTable',
    mixins: [textFormat, tableHelpers],
    props: ['data', 'itemsPerPage'],
    computed: {
        coupons() {
            return this.data.data
        },
    },
    data() {
        return {
            selected: null,
            searchQuery: '',
            status: 'All',
        }
    },
    methods: {
        selectedCoupon(data) {
            this.$emit('selectedCoupon', data.code)
        },
        search(value) {
            if (value.length > 3 || value === '') {
                this.searchQuery = value
                this.$emit('search', value)
            }
        },
        setLimit(status, value) {
            this.status = status
            this.$emit('limit', value)
        },
        handleSort(key, value) {
            this.$emit('sort', [key, value])
        },
        brokerAccount(coupon) {
            if (coupon && coupon.code) {
                var code = coupon.code
                if (code.includes('-bac-')) return true
                return false
            }
            return false
        },
    },
}
</script>
